import React, { ReactElement, useEffect, useState } from "react";
import Scroll from "react-scroll";
import {
  InteractiveWorkResponse,
  viewWorkType,
} from "../../models/InteractiveWork";
import { InteractiveWorkChatResponse } from "../../models/InteractiveWorkChat";
import { putRequestAsync } from "../../utils/api_client";
import BaseModal from "../shared/base_modal";
import Button from "../shared/button";
import Chat from "./chat_screen/chat";
import ChatInProgress from "./chat_screen/chat_in_progress";
import ChatInput from "./chat_screen/chat_input";
import ChatScreenMenuArea from "./chat_screen/chat_screen_menu_area";
import LastChat from "./chat_screen/last_chat";

interface Props {
  interactiveWork: InteractiveWorkResponse;
  interactiveWorkChats: InteractiveWorkChatResponse[];
  highHappinessElements: string[];
  lowHappinessElements: string[];
  lambdaFunctionName: string;
  awsAccessRoleName: string;
}

const scroll = Scroll.animateScroll;

function scrollToBottomOfChatContent(): void {
  scroll.scrollToBottom({
    containerId: "chatContentArea",
    duration: 100,
  });
}

function getInitialInputMode(
  interactiveWork: InteractiveWorkResponse,
  interactiveWorkChats: InteractiveWorkChatResponse[]
): "assistant" | "user" | "finished" {
  const lastChat: InteractiveWorkChatResponse | undefined =
    interactiveWorkChats.slice(-1)[0];
  let inputMode: "assistant" | "user" | "finished" = "assistant";
  if (interactiveWork.finished_at !== null) {
    inputMode = "finished";
  } else if (lastChat !== undefined && lastChat.role === "assistant_role") {
    inputMode = "user";
  }
  return inputMode;
}

export default function ChatScreen(props: Props): ReactElement {
  const {
    interactiveWork: initialInteractiveWork,
    interactiveWorkChats,
    highHappinessElements,
    lowHappinessElements,
    lambdaFunctionName,
    awsAccessRoleName,
  } = props;
  const [interactiveWork, setInteractiveWork] = useState(
    initialInteractiveWork
  );
  // idはダミーなので注意
  const [tempInteractiveWorkChats, setTempInteractiveWorkChats] = useState<
    InteractiveWorkChatResponse[]
  >([]);
  const [messageInProgress, setMessageInProgress] = useState<string>();
  const [inputMode, setInputMode] = useState(
    getInitialInputMode(interactiveWork, interactiveWorkChats)
  );
  const [errorModalMode, setErrorModalMode] = useState<
    | "due_to_excess_count_forced_exit_type"
    | "due_to_excess_length_forced_exit_type"
  >();

  useEffect(() => {
    setTimeout(() => {
      scrollToBottomOfChatContent();
    }, 100);
  }, [inputMode]);

  const setFinishedAt = async (
    type:
      | "due_to_excess_count_forced_exit_type"
      | "due_to_excess_length_forced_exit_type"
  ) => {
    const { result } = await putRequestAsync<InteractiveWorkResponse>(
      `/user/interactive_works/${interactiveWork.id}/change_to_complete`,
      {
        interactive_work: {
          forced_exit_type: type,
        },
      }
    );
    if (result !== undefined) {
      setInteractiveWork(result);
      setInputMode("finished");
    }
  };

  const isValidCount = (): boolean => {
    const chats = interactiveWorkChats.concat(tempInteractiveWorkChats);
    // 20回をこえる会話入力は許さない
    if (chats.filter((c) => c.role === "user_role").length > 20) {
      setErrorModalMode("due_to_excess_count_forced_exit_type");
      setFinishedAt("due_to_excess_count_forced_exit_type");
      return false;
    }
    return true;
  };

  return (
    <div className="chat-screen__container">
      {errorModalMode !== undefined && (
        <BaseModal
          show={true}
          contentLabel="SuggestNewWorkModal"
          title="エラーが発生しました"
          onCloseModal={() => {
            setErrorModalMode(undefined);
          }}
        >
          <BaseModal.Body>
            <>
              {errorModalMode === "due_to_excess_count_forced_exit_type" && (
                <div className="text-center">
                  会話が想定回数を超えているため、
                  <br />
                  申し訳ございませんが初めからワークをやり直してください。
                </div>
              )}
              {errorModalMode === "due_to_excess_length_forced_exit_type" && (
                <div className="text-center">
                  大変申し訳ございません。ハッピーAIが想定外の動きをしてしまいました。
                  <br />
                  御手数ですが、再度ワークをやり直してください。
                </div>
              )}
            </>
            <div className="d-flex justify-content-center mt-3">
              <Button
                title="新規対話ワーク作成"
                className="btn button__light"
                onClick={() => {
                  window.location.href = "/user/interactive_works/new";
                }}
              />
            </div>
          </BaseModal.Body>
        </BaseModal>
      )}
      <ChatScreenMenuArea currentInteractiveWork={interactiveWork} />
      <div className="chat-screen__chat-container">
        <div className="chat-screen__chat-title-container">
          {viewWorkType(interactiveWork.work_type)}
        </div>
        <div
          id="chatContentArea"
          className="chat-screen__chat-content-container py-5"
        >
          {interactiveWorkChats.map((iwc) => (
            <Chat key={iwc.id} role={iwc.role} body={iwc.message} />
          ))}
          {tempInteractiveWorkChats.map((iwc) => (
            <Chat key={iwc.id} role={iwc.role} body={iwc.message} />
          ))}
          <ChatInProgress
            interactiveWork={interactiveWork}
            interactiveWorkChats={interactiveWorkChats.concat(
              tempInteractiveWorkChats
            )}
            userMessage={messageInProgress}
            highHappinessElements={highHappinessElements}
            lowHappinessElements={lowHappinessElements}
            lambdaFunctionName={lambdaFunctionName}
            awsAccessRoleName={awsAccessRoleName}
            onChange={() => {
              scrollToBottomOfChatContent();
            }}
            onFinish={(chats) => {
              setTempInteractiveWorkChats(
                tempInteractiveWorkChats.concat(chats)
              );
              setMessageInProgress(undefined);
              const lastChat = chats.slice(-1)[0];
              if (
                lastChat !== undefined &&
                lastChat.role === "assistant_role" &&
                (lastChat.message.includes("●▲★■◆") ||
                  lastChat.message.includes("●▲★これにてワーク終了です■◆♥︎"))
              ) {
                setInputMode("finished");
              } else {
                setInputMode("user");
              }
            }}
            onExcessLengthError={() => {
              setErrorModalMode("due_to_excess_length_forced_exit_type");
              setFinishedAt("due_to_excess_length_forced_exit_type");
            }}
          />
          {inputMode === "finished" && (
            <LastChat interactiveWork={interactiveWork} />
          )}
        </div>
        <div className="chat-screen__chat-input-container">
          {inputMode === "finished" && (
            <div className="p-3 mb-4 text-center color__gray">
              {interactiveWork.forced_exit_flg && (
                <>
                  {interactiveWork.forced_exit_type ===
                    "due_to_excess_count_forced_exit_type" && (
                    <span>
                      申し訳ありません。
                      <br />
                      会話が想定回数を超えているため終了されました。
                    </span>
                  )}
                  {interactiveWork.forced_exit_type ===
                    "due_to_excess_length_forced_exit_type" && (
                    <span>
                      申し訳ありません。
                      <br />
                      ハッピーAIが想定外の動きをしてしまい終了されました。
                    </span>
                  )}
                </>
              )}
              {!interactiveWork.forced_exit_flg && (
                <span>
                  ワークが終了しました✨
                  <br />
                  これからの人生における幸せのキッカケとなれば幸いです
                </span>
              )}
            </div>
          )}
          {inputMode !== "finished" && (
            <ChatInput
              disabled={inputMode !== "user"}
              onSend={(msg) => {
                if (isValidCount()) {
                  setMessageInProgress(msg);
                  setInputMode("assistant");
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
