import {
  InvokeWithResponseStreamCommand,
  LambdaClient,
} from "@aws-sdk/client-lambda";
import { fromWebToken } from "@aws-sdk/credential-providers";
import { AwsCredentialIdentityProvider } from "@aws-sdk/types/dist-types/identity/AwsCredentialIdentity";
import { add, isBefore } from "date-fns";
import React, { ReactElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { InteractiveWorkResponse } from "../../../models/InteractiveWork";
import { InteractiveWorkChatResponse } from "../../../models/InteractiveWorkChat";
import { getRequestAsync, postRequestAsync } from "../../../utils/api_client";
import { getActiveMessage } from "../../../utils/chat_utils";
import Chat from "./chat";

interface Props {
  interactiveWork: InteractiveWorkResponse;
  interactiveWorkChats: InteractiveWorkChatResponse[];
  highHappinessElements: string[];
  lowHappinessElements: string[];
  userMessage?: string;
  lambdaFunctionName: string;
  awsAccessRoleName: string;
  onChange: () => void;
  onFinish: (createdChats: InteractiveWorkChatResponse[]) => void;
  onExcessLengthError: () => void;
}

const getOpenIdToken = async (): Promise<string | undefined> => {
  const { result } = await postRequestAsync<{ token: string }>(
    "/user/aws/get_open_id_token"
  );
  if (result !== undefined) {
    return result.token;
  }
};

export default function ChatInProgress(props: Props): ReactElement | null {
  const {
    interactiveWorkChats,
    interactiveWork,
    highHappinessElements,
    lowHappinessElements,
    userMessage,
    lambdaFunctionName,
    awsAccessRoleName,
    onChange,
    onFinish,
    onExcessLengthError,
  } = props;
  const [credentialObject, setCredentialObject] = useState<{
    credential: AwsCredentialIdentityProvider;
    expireTime: Date;
  }>();
  const [loading, setLoading] = useState(false);
  const [assistantMessage, setAssistantMessage] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (userMessage !== undefined || interactiveWorkChats.length === 0) {
      loadAssistantMessage();
    }
  }, [userMessage]);

  useEffect(() => {
    if (retryCount > 0) {
      if (retryCount >= 3) {
        // リトライ回数超過
        // APIのレスポンスパラメータ仕様がいきなり変わったのかわからないが、この分岐から抜け出せず対話ワーク自体が動かなくなる事象が発生したので、
        // この分岐に入ったらハッピーAIがちゃんと動いていることの確認を促すようなエラーメッセージにしている。
        window.Raven.captureException(
          new Error(
            "ハッピーAIの対話ワークで不具合が起きている可能性があります。確認してください。"
          )
        );
        window.alert(
          "ハッピーAIに問題が発生しました。すみませんがブラウザをリロードして再実行してみてください。"
        );
      } else {
        setTimeout(() => {
          setAssistantMessage("");
          loadAssistantMessage();
        }, 1000);
      }
    }
  }, [retryCount]);

  useEffect(() => {
    if (isFinished) {
      postRequestAsync(
        `/user/interactive_works/${interactiveWork.id}/interactive_work_chats`,
        {
          interactive_work_chat: {
            user_message: userMessage,
            assistant_message: assistantMessage,
          },
        }
      );
      initStates();
      onFinish(
        userMessage === undefined
          ? [
              {
                id: uuidv4(),
                role: "assistant_role",
                message: assistantMessage,
              },
            ]
          : [
              { id: uuidv4(), role: "user_role", message: userMessage },
              {
                id: uuidv4(),
                role: "assistant_role",
                message: assistantMessage,
              },
            ]
      );
    }
  }, [isFinished]);

  const initStates = () => {
    setIsFinished(false);
    setLoading(false);
    setAssistantMessage("");
    setRetryCount(0);
  };

  const checkConsistency = async (): Promise<boolean> => {
    const { result } = await getRequestAsync<{ count: number }>(
      `/user/interactive_works/${interactiveWork.id}/interactive_work_chats/count`
    );
    if (result !== undefined) {
      return result.count === interactiveWorkChats.length;
    }
    return false;
  };

  const getCredential = async (): Promise<
    AwsCredentialIdentityProvider | undefined
  > => {
    if (
      credentialObject !== undefined &&
      isBefore(new Date(), credentialObject.expireTime)
    ) {
      return credentialObject.credential;
    }
    const openIdToken = await getOpenIdToken();
    if (openIdToken === undefined) {
      return;
    }
    const c = fromWebToken({
      roleArn: awsAccessRoleName,
      webIdentityToken: openIdToken,
    });
    setCredentialObject({
      credential: c,
      expireTime: add(new Date(), { minutes: 10 }),
    });
    return c;
  };

  const loadAssistantMessage = async (): Promise<void> => {
    setLoading(true);
    const isValid = await checkConsistency();
    if (!isValid) {
      setLoading(false);
      window.alert(
        "チャットが古くなっている可能性があります。申し訳ありませんが、ブラウザをリロードしてください"
      );
      return;
    }
    const c = await getCredential();
    if (c === undefined) {
      window.alert(
        "認証情報が取得できませんでした。ブラウザをリロードして再度お試しください"
      );
      setLoading(false);
      return;
    }
    const lambdaClient = new LambdaClient({
      region: "ap-northeast-1",
      credentials: c,
    });
    const encoder = new TextEncoder();
    const data = await lambdaClient.send(
      new InvokeWithResponseStreamCommand({
        FunctionName: lambdaFunctionName,
        Payload: encoder.encode(
          JSON.stringify({
            interactiveWorkId: interactiveWork.id,
            workType: interactiveWork.work_type,
            interactiveWorkChats,
            userMessage,
            highHappinessElements,
            lowHappinessElements,
          })
        ),
      })
    );
    if (data.EventStream === undefined) {
      window.alert(
        "予期しないエラーが発生しました。ブラウザをリロードして再実行してみてください。"
      );
      return;
    }

    const decoder = new TextDecoder();
    for await (const d of data.EventStream) {
      if (d.PayloadChunk === undefined) {
        if (d.InvokeComplete?.ErrorCode !== undefined) {
          setRetryCount((c) => c + 1);
          return;
        }
        setIsFinished(true);
        return;
      }
      const uint8array = d.PayloadChunk.Payload;
      const rawStr = decoder.decode(uint8array);
      const str = getActiveMessage(rawStr);
      if (str === "●▲★長さ超過■◆♥︎") {
        onExcessLengthError();
      } else {
        setAssistantMessage((currentValue) => currentValue + str);
      }
      onChange();
    }
  };

  return (
    <>
      {userMessage !== undefined && (
        <Chat role="user_role" body={userMessage} />
      )}
      {loading && (
        <Chat role="assistant_role" body={assistantMessage} loading />
      )}
    </>
  );
}
