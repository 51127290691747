import React, { ReactElement, useEffect, useState } from "react";
import { InteractiveHappinessAdviceResponse } from "../../../models/InteractiveHappinessAdvice";
import { InteractiveHappinessAdviceIndexResponse } from "../../../models/InteractiveHappinessAdviceApi";
import { getRequestAsync } from "../../../utils/api_client";
import BaseForm from "../../shared/base_form";
import Button from "../../shared/button";
import Loading from "../../shared/loading";
import ChatHistoryItem from "./chat_history_item";

interface Props {
  currentInteractiveHappinessAdvice: InteractiveHappinessAdviceResponse;
  historyUpdated: boolean;
}

export default function ChatScreenMenuArea(props: Props): ReactElement {
  const { currentInteractiveHappinessAdvice, historyUpdated } = props;
  const [
    interactiveHappinessAdviceHistories,
    setInteractiveHappinessAdviceHistories,
  ] = useState<InteractiveHappinessAdviceIndexResponse[]>([]);

  useEffect(() => {
    loadHistories();
  }, []);

  useEffect(() => {
    if (historyUpdated) {
      setTimeout(() => {
        loadHistories();
      }, 500);
    }
  }, [historyUpdated]);

  const loadHistories = async (): Promise<void> => {
    const { result } = await getRequestAsync<
      InteractiveHappinessAdviceIndexResponse[]
    >("/user/interactive_happiness_advices");
    if (result !== undefined) {
      setInteractiveHappinessAdviceHistories(result);
    }
  };

  const destroy = async (): Promise<void> => {
    if (window.confirm("このアドバイスを削除してよろしいですか？")) {
      const submit = document.getElementById("chatDestroySubmitOfMobile");
      if (submit !== null) {
        submit.click();
      }
    }
  };

  return (
    <div className="chat-screen__menu-container">
      <div className="chat-screen__menu-top-container">
        <div className="chat-screen__interactive-work-destroy-button-container">
          <BaseForm
            name="chatDestroyFormOfMobile"
            action={`/user/interactive_happiness_advices/${currentInteractiveHappinessAdvice.id}`}
            method="delete"
            type="normal"
            style={{ height: 20 }}
          >
            <div>
              <Button
                title="削除"
                className="btn button__light"
                onClick={() => {
                  destroy().catch((e) => console.log(e));
                }}
              />
            </div>
            <button
              type="submit"
              id="chatDestroySubmitOfMobile"
              style={{ border: "none", padding: 0 }}
            />
          </BaseForm>
        </div>
        <div>
          <Button
            title="履歴"
            className="btn button__light chat-screen__interactive-work-history-button"
            onClick={() => {
              window.location.href = `/user/interactive_happiness_advices/${currentInteractiveHappinessAdvice.id}/histories`;
            }}
          />
          <a
            href="/user/interactive_happiness_advices/new"
            className="btn button__light chat-screen__interactive-work-new-button"
          >
            新規アドバイス
          </a>
        </div>
      </div>
      <div className="chat-screen__menu-history-container">
        <div className="font__size-13 color__gray mt-1">履歴</div>
        {interactiveHappinessAdviceHistories.length === 0 && (
          <div className="position-relative" style={{ height: 50 }}>
            <Loading loading={true} width={20} height={20} />
          </div>
        )}
        {interactiveHappinessAdviceHistories.map((ihah) => {
          return (
            <ChatHistoryItem
              key={ihah.id}
              interactiveHappinessAdvice={ihah}
              title={ihah.initial_interactive_happiness_advice_chat?.message}
              isCurrentAdvice={ihah.id === currentInteractiveHappinessAdvice.id}
            />
          );
        })}
        {interactiveHappinessAdviceHistories.length > 19 && (
          <div className="text-right mt-2">
            <Button
              className="color__link font__size-13 cursor__pointer"
              title="もっとみる"
              onClick={() => {
                window.location.href = `/user/interactive_happiness_advices/${currentInteractiveHappinessAdvice.id}/histories`;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
