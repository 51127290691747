import React, { ReactElement, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import TextareaAutosize from "react-textarea-autosize";
import Button from "../../shared/button";
import SelectField from "../../shared/form/fields/select_field";

interface Props {
  disabled?: boolean;
  onChangeOptions: (
    gpt: "gpt3" | "gpt4",
    searchType: "simple" | "extract_keywords"
  ) => void;
  onSend: (message: string) => void;
}

export default function ChatInput(props: Props): ReactElement {
  const { disabled = false, onSend, onChangeOptions } = props;
  const [refreshingTextarea, setRefreshingTextarea] = useState(false);
  const [gpt, setGpt] = useState<"gpt3" | "gpt4">("gpt3");
  const [searchType, setSearchType] = useState<"simple" | "extract_keywords">(
    "simple"
  );
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (refreshingTextarea) {
      setRefreshingTextarea(false);
    }
  }, [refreshingTextarea]);

  useEffect(() => {
    if (!disabled) {
      if (textareaRef.current !== null) {
        textareaRef.current.focus();
      }
    }
  }, [disabled]);

  useEffect(() => {
    onChangeOptions(gpt, searchType);
  }, [gpt, searchType]);

  const sendMessage = () => {
    if (textareaRef.current !== null) {
      const val = textareaRef.current?.value;
      const fixedVal = val.trim();
      if (fixedVal !== "") {
        onSend(fixedVal);
        setRefreshingTextarea(true);
      } else {
        window.alert("文字が入力されていません");
      }
    }
  };

  return (
    <>
      <div className="d-flex p-2">
        <SelectField
          id="gpt_selector"
          name="gpt_selector"
          options={[
            { key: "gpt3", value: "GPT-3" },
            { key: "gpt4", value: "GPT-4" },
          ]}
          selectedKey={gpt}
          onChange={(e) => {
            setGpt(e.target.value as "gpt3" | "gpt4");
          }}
        />
        {/* <div className="ml-2">
          <SelectField
            id="search_type_selector"
            name="search_type_selector"
            options={[
              { key: "simple", value: "質問で検索" },
              { key: "extract_keywords", value: "キーワード抽出して検索" },
            ]}
            selectedKey={searchType}
            onChange={(e) => {
              setSearchType(e.target.value as "simple" | "extract_keywords");
            }}
          />
        </div> */}
      </div>
      <div className="d-flex justify-content-between p-2 mb-2">
        <div className="flex-fill">
          {!refreshingTextarea && (
            <TextareaAutosize
              ref={textareaRef}
              className="form-control shadow-sm"
              style={{ width: "100%", resize: "none" }}
              minRows={2}
              maxRows={10}
              placeholder="質問を入力してください"
              disabled={disabled}
              onKeyDown={(e) => {
                if (
                  !isMobile &&
                  e.code === "Enter" &&
                  !e.nativeEvent.isComposing &&
                  !e.nativeEvent.shiftKey
                ) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
          )}
        </div>
        <div className="d-flex align-items-end ml-2">
          <Button
            title="送信"
            className="btn button__primary"
            onClick={sendMessage}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
}
