import PropTypes from 'prop-types';
import React from 'react';
import BaseMoal from '../shared/base_modal';
import Button from '../shared/button';
import ShareImageView from './shared/share_image_view';
import { getAfterMeasurementModalLoadCount, setAfterMeasurementModalLoadCount } from '../../utils/global_variable_utils';
import { postRequest } from '../../utils/api_client';
import HappinessStructureExampleImage from '../shared/images/happiness_structure_example.png';
import HappinessStructureExampleImageEn from '../shared/images/happiness_structure_example_en.png';
import HappinessStructureImageView from './shared/happiness_structure_image_view';

function tracking() {
  postRequest('/user/tracks', { happiness_structure_link_type: 'after_measurement' });
}
const isJaLocale = window.I18n.locale === 'ja';

class AfterMeasurementModal extends React.Component {
  constructor(props) {
    super(props);
    let showModal = false;
    // well-being-circleのpdf作成で2回レンダリングされてしまうので、1回目だけmodalを表示させている
    if (getAfterMeasurementModalLoadCount() === 0) {
      setAfterMeasurementModalLoadCount(1);
      showModal = true;
    }
    this.state = {
      showModal,
      showShareImage: false,
      loading: false,
    };
  }

  render() {
    const { questionnaire, snsShareCount, isConsumer } = this.props;
    const { showModal, showShareImage, loading } = this.state;
    let modalTitle = window.I18n.t('javascript.questionnaires.after_measurement_modal.thank_you_for_your_measurement');
    if (showShareImage) {
      if (isConsumer) {
        modalTitle = window.I18n.t('javascript.questionnaires.after_measurement_modal.share_happiness_structure');
      } else {
        modalTitle = window.I18n.t('javascript.questionnaires.questionnaire_share_link.happiness_structure');
      }
    }
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={modalTitle}
          contentLabel="afterMeasurementModal"
          loading={loading}
          contentStyle={{ maxWidth: 496, width: '100%' }}
          onCloseModal={() => this.setState({ showModal: false })}
        >
          {isConsumer && showShareImage && (
            <ShareImageView
              questionnaire={questionnaire}
              snsShareCount={snsShareCount}
              beforeLoading={callback => this.setState({ loading: true }, callback)}
              afterLoading={() => this.setState({ loading: false })}
              showLookBackSuggestMessage
            />
          )}
          {!isConsumer && showShareImage && (
            <div>
              <HappinessStructureImageView
                questionnaire={questionnaire}
                beforeLoading={callback => this.setState({ loading: true }, callback)}
                afterLoading={() => this.setState({ loading: false })}
                showLookBackSuggestMessage
              />
            </div>
          )}
          {!showShareImage && (
            <BaseMoal.Body style={{ maxHeight: '70vh' }}>
              <div className="container-fluid">
                <div>
                  {questionnaire.nickname && (
                    <p>
                      {window.I18n.t('javascript.questionnaires.after_measurement_modal.message1_with_nickname', { nickname: questionnaire.nickname })}
                    </p>
                  )}
                  {!questionnaire.nickname && (
                    <p>
                      {window.I18n.t('javascript.questionnaires.after_measurement_modal.message1')}
                    </p>
                  )}
                </div>
                <div className="mt-2">
                  <p>
                    {window.I18n.t('javascript.questionnaires.after_measurement_modal.message2')}
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src={isJaLocale ? HappinessStructureExampleImage : HappinessStructureExampleImageEn}
                    alt={window.I18n.t('javascript.questionnaires.after_measurement_modal.share_image')}
                    className="width__50_75"
                  />
                </div>
                <div className="text-center color__gray font__size-13">
                  {window.I18n.t('javascript.questionnaires.after_measurement_modal.example_of_happiness_structure')}
                </div>
                <div className="mt-2">
                  {questionnaire.nickname && (
                    <p>
                      {window.I18n.t('javascript.questionnaires.after_measurement_modal.message3_with_nickname', { nickname: questionnaire.nickname })}
                    </p>
                  )}
                  {!questionnaire.nickname && (
                    <p>
                      {window.I18n.t('javascript.questionnaires.after_measurement_modal.message3')}
                    </p>
                  )}
                </div>
                <Button
                  title={window.I18n.t('javascript.questionnaires.after_measurement_modal.show_happiness_structure')}
                  className="btn button__primary d-flex justify-content-center mt-2"
                  onClick={() => this.setState({ showShareImage: true }, tracking)}
                  style={{ zIndex: 10 }}
                />
              </div>
            </BaseMoal.Body>
          )}
        </BaseMoal>
      </React.Fragment>
    );
  }
}
AfterMeasurementModal.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
    share_token: PropTypes.string,
    share_image_url: PropTypes.string,
  }).isRequired,
  snsShareCount: PropTypes.number,
  isConsumer: PropTypes.bool.isRequired,
};
AfterMeasurementModal.defaultProps = {
  snsShareCount: 0,
};
export default AfterMeasurementModal;
