import React, { ReactElement } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import HappyBirdImage from "../../shared/images/happy_bird.png";
import MultiLineText from "../../shared/multi_line_text";
import Loading from "../../shared/loading";

interface Props {
  role: "assistant_role" | "user_role";
  body: string;
  loading?: boolean;
}

export default function Chat(props: Props): ReactElement {
  const { role, body, loading = false } = props;

  if (role === "assistant_role") {
    return (
      <div className="chat-screen__chat-message-container">
        <div className="d-flex justify-content-start">
          <div className="ml-3">
            <img src={HappyBirdImage} style={{ width: 28 }} />
          </div>
          {loading && (
            <div className="position-relative ml-3">
              <Loading loading={true} width={20} height={20} />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-start px-3 pb-3 mt-1">
          <div
            className="p-3 bgcolor__trivial rounded"
            style={{ maxWidth: "80%" }}
          >
            <ReactMarkdown children={body} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="chat-screen__chat-message-container">
      <div className="d-flex justify-content-end p-3">
        <div
          className="p-3 bgcolor__trivial rounded"
          style={{ maxWidth: "80%" }}
        >
          <MultiLineText text={body} />
        </div>
      </div>
    </div>
  );
}
