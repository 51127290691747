import React, { ReactElement } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "../../shared/button";
import { QuestionnaireAdviceResponse } from "../../../models/QuestionnaireAdvice";
import TextareaField from "../../shared/form/fields/textarea_field";

interface Props {
  questionnaireAdvice: QuestionnaireAdviceResponse;
  hashTags: string[];
}

export default function AdviceInstagramShareView(props: Props): ReactElement {
  const { questionnaireAdvice, hashTags } = props;

  const shareText = hashTags.map((h) => `#${h}`).join(" ");

  return (
    <>
      <div className="font__size-14 p-2 ml-2">
        {window.I18n.t(
          "javascript.questionnaires.shared.instagram_share_step_1"
        )}
      </div>
      <div className="text-center p-3">
        <img
          src={questionnaireAdvice.ogp_image_url}
          alt={window.I18n.t("javascript.questionnaires.shared.share_image")}
          className="w-50"
        />
      </div>
      <div className="font__size-14 p-2 ml-2">
        {window.I18n.t(
          "javascript.questionnaires.shared.instagram_share_step_2"
        )}
      </div>
      <div className="text-right m-2">
        <CopyToClipboard
          text={`${shareText}\n${questionnaireAdvice.share_link}`}
          onCopy={() =>
            window.alert(
              window.I18n.t("javascript.questionnaires.shared.copied_clipboad")
            )
          }
        >
          <Button
            title={window.I18n.t("javascript.questionnaires.shared.copy")}
            className="btn btn-small btn-light"
          />
        </CopyToClipboard>
      </div>
      <div className="mx-2 mb-5">
        <TextareaField
          id=""
          name=""
          value={`${shareText}\n${questionnaireAdvice.share_link}`}
          rows={4}
          style={{ fontSize: 13 }}
          readOnly
        />
      </div>
    </>
  );
}
