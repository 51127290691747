import React, { ReactElement, useState } from "react";
import { QuestionnaireResponse } from "../../models/Questionnaire";
import { QuestionnaireAdviceResponse } from "../../models/QuestionnaireAdvice";
import BaseModal from "../shared/base_modal";
import Button from "../shared/button";
import HappyBirdImage from "../shared/images/happy_bird.png";
import AdviceContent from "./advice_button/advice_content";

interface Props {
  questionnaire: QuestionnaireResponse;
  comparisonQuestionnaire: QuestionnaireResponse | null;
  title: string;
  comparisonQuestionnaireAdvice: QuestionnaireAdviceResponse | null;
  questionnaireAdvice: QuestionnaireAdviceResponse | null;
  interactiveWorkPath: string;
  interactiveHappinessAdvicePath: string;
  isConsumer: boolean;
}

export default function AdviceButton(props: Props): ReactElement {
  const {
    questionnaire,
    title,
    comparisonQuestionnaire,
    comparisonQuestionnaireAdvice,
    questionnaireAdvice,
    interactiveWorkPath,
    interactiveHappinessAdvicePath,
    isConsumer,
  } = props;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {showModal && (
        <BaseModal
          show={showModal}
          title={
            <div className="d-flex align-items-center">
              <div>
                <img src={HappyBirdImage} style={{ width: 30 }} />
              </div>
              <div className="ml-2">ハッピーAI</div>
            </div>
          }
          loading={loading}
          contentLabel="questionnaireAdvicesModal"
          onCloseModal={() => setShowModal(false)}
          contentStyle={{ maxWidth: 496, width: "100%" }}
        >
          <BaseModal.Body>
            <div>
              <div className="row p-2">
                <div className="col-md-6 py-1">
                  <Button
                    className="btn button__highlignt font__size-14 width__100"
                    onClick={() => {
                      window.location.href = interactiveWorkPath;
                    }}
                  >
                    <div>
                      ハッピーAIとの
                      <br />
                      対話ワーク
                    </div>
                  </Button>
                </div>
                <div className="col-md-6 py-1">
                  <Button
                    className="btn button__highlignt font__size-14 width__100"
                    disabled
                    // onClick={() => {
                    //   window.location.href = interactiveHappinessAdvicePath;
                    // }}
                  >
                    <div>
                      より幸福度を伸ばす
                      <br />
                      アドバイス(開発中)
                    </div>
                  </Button>
                </div>
              </div>
              <div className="mt-2 font-weight-bold font__size-14">
                ハッピーAIからのメッセージ
              </div>
              <AdviceContent
                questionnaire={questionnaire}
                questionnaireAdvice={questionnaireAdvice}
                comparisonQuestionnaire={comparisonQuestionnaire}
                comparisonQuestionnaireAdvice={comparisonQuestionnaireAdvice}
                isConsumer={isConsumer}
                beforeLoading={() => setLoading(true)}
                afterLoading={() => setLoading(false)}
              />
            </div>
          </BaseModal.Body>
        </BaseModal>
      )}
      <div
        className="btn button__highlignt w-100"
        onClick={() => setShowModal(true)}
        id="happy_ai_button"
      >
        {title}
      </div>
    </>
  );
}
